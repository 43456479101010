// CHANGE COLOR WHEN SCROLLING NAVBAR
 $(window).scroll(function(){
               
     $('nav').toggleClass('scrolled',$(this).scrollTop()>50);

     if ($(this).scrollTop() > 50) { 
          $("#sticky-button").css('display', 'block');
     }
     else if ($(this).scrollTop() <= 50) { 
          $("#sticky-button").css('display','none');
     }

     var scroll_pos = 0;
     var x = window.matchMedia("(max-width: 1199.98px)");
     scroll_pos = $(this).scrollTop();
      if(scroll_pos > 50) {
          $(".navbarChange a").css('color', 'black');
      } else {
          $(".navbarChange").css('background', 'transparent');

          if(x.matches){
               $(".navbarChange a").css('color', 'black');
          }else{
               $(".navbarChange a").css('color', 'white');
          }
          
      }

});

// PRELOADER
setTimeout(function(){
     $('.preloader').fadeToggle();
},1000);




