// !Headline Competition
$(document).ready(function() {
var owl_articles = $(".articles-carousel");
owl_articles.owlCarousel({
    autoplay: true,
    center: true,
    loop: true,
    dots: true,
    lazyLoad: true,
    nav: false,
    items: 1,
    margin: 23,
    responsive: {
        0: {
            items: 1,
        },
        552: {
            items: 1,
        },
        768: {
            items: 3,
        },
        992: {
            items: 3,
        },
        1200: {
            loop: true,
            dots: true,
            stagePadding: 0,
            items: 3,
        }
    }
});

owl_articles.on("mousewheel", ".owl-stage", function(e) {
    if (e.deltaY > 0) {
        owl_articles.trigger("prev.owl");
    } else {
        owl_articles.trigger("next.owl");
    }
    e.preventDefault();
});

var owl_support = $(".support-carousel");
owl_support.owlCarousel({
    autoplay: true,
    center: true,
    loop: true,
    dots: false,
    lazyLoad: true,
    nav: false,
    items: 1,
    margin: 23,
    responsive: {
        0: {
            items: 2,
        },
        552: {
            items: 3,
        },
        768: {
            items: 3,
        },
        992: {
            items: 5,
        },
        1200: {
            loop: true,
            stagePadding: 0,
            items: 5,
        }
    }
});

owl_support.on("mousewheel", ".owl-stage", function(e) {
    if (e.deltaY > 0) {
        owl_support.trigger("prev.owl");
    } else {
        owl_support.trigger("next.owl");
    }
    e.preventDefault();
});

});